import {CreatedByProfileFormatter} from '../../../components/CreatedByProfileFormatter';
import {Price,} from '../types';
import {PlatformPropertyCard} from "@flexinet/ui-components";


export const Profile = ({record}: { record: Price }) => {
    console.debug(record)

    const properties = [
        {
            label: 'ID',
            value: record.id as string,
            span: 6,
        }, {
            label: 'Product',
            value: record.productId as string,
            span: 6,
        }, {
            label: 'Pricing Model',
            value: record.pricingModel as string,
            span: 6,
        }, {
            label: 'Rate Plan',
            value: record.ratePlanId as string,
            span: 6,
        }, {
            label: 'Created By',
            value: <CreatedByProfileFormatter record={record as any}/>,
            rbac: 'UpdatePrice',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }];


    return (
        <PlatformPropertyCard properties={properties}/>
    );


};
