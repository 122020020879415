import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/BillingTransactionsService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {BillingTransaction} from '../types';

const entityKey = 'billingTransactions';

interface BillingTransactionsState {
    byId: { [key: string]: BillingTransaction },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as BillingTransactionsState;


const normalizeRecord = (record: BillingTransaction) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchBillingTransactions = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {

        const query = {
            // billingProviderId: '2ec65b2a-0741-4369-92ea-bc50fabe8b8f',
            // billingProviderRecordId: 'cus_Rs5OWbHDnk3qj6'
        }

        return fetchRecords(query)
            .then((response) => {
                if (response) {
                    return response.map((record: BillingTransaction) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchBillingTransaction = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const saveBillingTransaction = createAsyncThunk(
    `${entityKey}/add`,
    (payload: BillingTransaction) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateBillingTransaction = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: BillingTransaction }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteBillingTransaction = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeBillingTransaction = createAsyncThunk(
    `${entityKey}/store`,
    (payload: BillingTransaction) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchBillingTransactions, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchBillingTransaction);

                                            // SAVE ONE
                                            saveOneCase(builder, saveBillingTransaction);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateBillingTransaction);

                                            updateOneCase(builder, storeBillingTransaction);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteBillingTransaction);
                                        },
                                    });

export default componentsSlice.reducer;
