import {ITableColumn} from '../../types';
import {ReactNode} from 'react';

export type MeterDimension = { name: string, apiKey: string }

export enum MeterType {
    Sum = 'Sum',
    UniqueCount = 'UniqueCount',
    Duration = 'Duration',
    Max = 'Max',
    MaxPersist = 'MaxPersist',
    RunningTotal = 'RunningTotal',
}

export enum MeterStatus {
    Draft = 'Draft',
    Active = 'Active',
    Deprecated = 'Deprecated',
}

export type Meter = {
    id: string,
    name: string,
    apiKey: string,
    type: MeterType,
    status: MeterStatus,
    dimensions: MeterDimension[],
    createdByUsername?: string,
    createdBy?: { givenName: string, familyName: string },
    created?: string,
    updated?: string,
}


export interface IMeterTableColumn extends ITableColumn {
    render?: (item: Meter) => ReactNode;
}
