import {ITableColumn} from '../../types';
import {ReactNode} from 'react';


export enum ProductStatus {
    'Active' = 'Active',
    'Deprecated' = 'Deprecated',
    'Draft' = 'Draft'
}


export type Product = {
    id: string,
    name: string,
    status: ProductStatus,
    description?: string,
    billingProviderId: string,
    billingProviderRecordId: string,
    current: boolean,
    baseFee: string,
    included: string,
    price: string,
    features: {
        title: string,
        supported: boolean
    }[],
    freeTrial: boolean
    productIds: string[],
    products?: {
        items: Product[],
        nextToken?: string
    }
    createdByUsername?: string,
    createdBy?: { username: string, givenName: string, familyName: string },
    created?: string,
    updated?: string,

    // experimental
    trialLength?: number,
    setupFee?: number,
    billingPeriod?: number,
    subscriptionTermLength?: number
    totalBillingCycles?: number
    autoRenew?: boolean,
    pricingModel?: string,
}


export interface IProductTableColumn extends ITableColumn {
    render?: (item: Product) => ReactNode;
}