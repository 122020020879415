export const listBillingTransactions = `
   query listBillingTransactions($filter: BillingTransactionsFilterInput) {
       listBillingTransactions(filter: $filter) {
           items {
              id
              name
              description
              status
              created
              updated
           }
       }
   }
`;

export const getBillingTransaction = `
   query getBillingTransaction($id:ID!) {
       getBillingTransaction(id:$id) {
         id
         name
         description 
         status
         created
         updated
       } 
}`;
