import {ITableColumn} from '../../types';
import {ReactNode} from 'react';
import {PaymentProfileSchema} from "./schemas";
import {z} from 'zod';

export type PaymentProfileDimension = { name: string, value: string }

export enum PaymentProfileStatus {
    Active = 'Active',
    Inactive = 'Inactive'
}

export type PaymentProfileContactInfo = {
    email?: string,
    phone?: string,
    website?: string,
}
export type PaymentProfileBillingInfo = {
    billingProviderId?: string
    billingProviderRecordId?: string,
    billingAddress?: string,
    billingCity?: string,
    billingState?: string,
    billingZip?: string
}

export type PaymentProfilePaymentInfo = {
    paymentMethod?: string,
}

export type PaymentProfile = z.infer<typeof PaymentProfileSchema>

// export type PaymentProfile = {
//     id: string,
//     name: string,
//     description?: string,
//     status: PaymentProfileStatus,
//     billingProviderId?: string
//     billingProviderRecordId?: string,
//     contactInfo: PaymentProfileContactInfo,
//     billingInfo?: PaymentProfileBillingInfo,
//     paymentInfo?: PaymentProfilePaymentInfo,
//     createdByUsername?: string,
//     createdBy?: { givenName: string, familyName: string },
//     created?: string,
//     updated?: string,
// }


export interface IPaymentProfileTableColumn extends ITableColumn {
    render?: (item: PaymentProfile) => ReactNode;
}
