import {Product,} from '../types';
import {PlatformPropertyCard} from "@flexinet/ui-components";
import {CreatedByProfileFormatter} from "../../../components/CreatedByProfileFormatter.tsx";


export const MetadataPropertyCard = ({record}: { record: Product }) => {

    const properties = [
        {
            label: 'Created By',
            value: <CreatedByProfileFormatter record={record as any}/>,
            rbac: 'UpdateServicePlan',
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];


    return (
        <PlatformPropertyCard properties={properties}/>
    );


};
