import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Price} from '../types';

export const columns = [
    {
        title: I18n.get('Price'),
        width: '1fr',
        render: (item: Price) => <NavLink to={`/prices/${item.id}`}>{item.name ? item.name : item.id}</NavLink>,
    },
    {
        title: I18n.get('Product'),
        width: '1fr',
        render: (item: Price) => <NavLink
            to={`/products/${item.productId}`}>{item.product ? item.product.name : item.productId}</NavLink>,
    },
    {
        title: I18n.get('Pricing Model'),
        width: '1fr',
        render: (item: Price) => item.pricingModel,
    },
    {
        title: I18n.get('Rate Plan'),
        width: '1fr',
        render: (item: Price) => <NavLink
            to={`/rate-plans/${item.ratePlanId}`}>{item.ratePlan ? item.ratePlan.name : item.ratePlanId}</NavLink>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Price) => formatTimestamp(item.created as string),
    },
];
