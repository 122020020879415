import {MeteringRecord,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";
import {DimensionsTable} from "../../../components/DimensionsTable.tsx";
import {CreatedByProfileFormatter} from "../../../components/CreatedByProfileFormatter.tsx";


export const Profile = ({record}: { record: MeteringRecord }) => {

    const properties = [
        {
            label: 'Customer',
            value: record.customerId as string,
            span: 6,
        }, {
            label: 'Meter',
            value: record.meterId as string,
            span: 6,
        }, {
            label: 'Meter Value',
            value: record.meterValue,
            span: 6,
        },
        {
            label: 'Timestamp',
            value: record.timestamp as string,
            formatAs: 'Timestamp',
            span: 6,
        }, {
            label: 'Dimensions',
            value: record.dimensions ? <DimensionsTable dimensions={record.dimensions.map(dimension => ({
                name: dimension.apiKey,
                value: dimension.value,
                copyDecorator: true,
            }))}/> : null,
            span: 12,
        }, {
            label: 'Created By',
            value: <CreatedByProfileFormatter record={record as any}/>,
            rbac: 'UpdateMeteringRecord',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (<PlatformPropertyCard properties={allowedProperties}/>);


};
