import {Meter,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";
import {CreatedByProfileFormatter} from '../../../components/CreatedByProfileFormatter.tsx';


export const MetadataPropertyCard = ({record}: { record: Meter }) => {

    const properties = [
        {
            label: 'Created By',
            value: <CreatedByProfileFormatter record={record as any}/>,
            rbac: 'UpdateMeter',
            span: 6,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 6,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 6,
        }] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (
        <PlatformPropertyCard properties={allowedProperties}/>
    );


};
