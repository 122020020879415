export const createRatePlan = `
    mutation createRatePlan($input: CreateRatePlanInput!) {
        createRatePlan(input:$input) {
            id
            name
            billableUnit
            billablePeriod
            chargeTiers {
                lowerBand
                upperBand
                rate
            }   
            created
            updated  
    }
}`;

export const updateRatePlan = `
    mutation updateRatePlan($input: UpdateRatePlanInput!) {
        updateRatePlan(input:$input){
            id
            name
            billableUnit
            billablePeriod
            chargeTiers {
                lowerBand
                upperBand
                rate
            }   
            created
            updated       
        }
}`;

export const deleteRatePlan = `
    mutation deleteRatePlan($input: DeleteRatePlanInput!) {
        deleteRatePlan(input:$input) 
}`;
