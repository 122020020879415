export enum rbacActions {
    ViewDashboard = 'ViewDashboard',
    ViewProfile = 'ViewProfile',


    ListBillingProviders = 'ListBillingProviders',
    GetBillingProvider = 'GetBillingProvider',
    CreateBillingProvider = 'CreateBillingProvider',
    UpdateBillingProvider = 'UpdateBillingProvider',
    DeleteBillingProvider = 'DeleteBillingProvider',

    ListMeteringRecords = 'ListMeteringRecords',
    GetMeteringRecord = 'GetMeteringRecord',
    CreateMeteringRecord = 'CreateMeteringRecord',
    UpdateMeteringRecord = 'UpdateMeteringRecord',
    DeleteMeteringRecord = 'DeleteMeteringRecord',

    ListUsageRecords = 'ListUsageRecords',
    GetUsageRecord = 'GetUsageRecord',
    CreateUsageRecord = 'CreateUsageRecord',
    UpdateUsageRecord = 'UpdateUsageRecord',
    DeleteUsageRecord = 'DeleteUsageRecord',

    ListMeters = 'ListMeters',
    GetMeter = 'GetMeter',
    CreateMeter = 'CreateMeter',
    UpdateMeter = 'UpdateMeter',
    DeleteMeter = 'DeleteMeter',

    ListOrders = 'ListOrders',
    GetOrder = 'GetOrder',
    CreateOrder = 'CreateOrder',
    UpdateOrder = 'UpdateOrder',
    DeleteOrder = 'DeleteOrder',

    ListProducts = 'ListProducts',
    GetProduct = 'GetProduct',
    CreateProduct = 'CreateProduct',
    UpdateProduct = 'UpdateProduct',
    DeleteProduct = 'DeleteProduct',

    ListProductFeatures = 'ListProductFeatures',
    GetProductFeature = 'GetProductFeature',
    CreateProductFeature = 'CreateProductFeature',
    UpdateProductFeature = 'UpdateProductFeature',
    DeleteProductFeature = 'DeleteProductFeature',


    ListRatePlans = 'ListRatePlans',
    GetRatePlan = 'GetRatePlan',
    CreateRatePlan = 'CreateRatePlan',
    UpdateRatePlan = 'UpdateRatePlan',
    DeleteRatePlan = 'DeleteRatePlan',

    ListSubscriptions = 'ListSubscriptions',
    GetSubscription = 'GetSubscription',
    CreateSubscription = 'CreateSubscription',
    UpdateSubscription = 'UpdateSubscription',
    DeleteSubscription = 'DeleteSubscription',

    ListInvoices = 'ListInvoices',
    GetInvoice = 'GetInvoice',
    CreateInvoice = 'CreateInvoice',
    UpdateInvoice = 'UpdateInvoice',
    DeleteInvoice = 'DeleteInvoice',

    ListInvoiceItems = 'ListInvoiceItems',
    GetInvoiceItem = 'GetInvoiceItem',
    CreateInvoiceItem = 'CreateInvoiceItem',
    UpdateInvoiceItem = 'UpdateInvoiceItem',
    DeleteInvoiceItem = 'DeleteInvoiceItem',

    ListPrices = 'ListPrices',
    GetPrice = 'GetPrice',
    CreatePrice = 'CreatePrice',
    UpdatePrice = 'UpdatePrice',
    DeletePrice = 'DeletePrice',

    ListBillingAccounts = 'ListBillingAccounts',
    GetBillingAccount = 'GetBillingAccount',
    CreateBillingAccount = 'CreateBillingAccount',
    UpdateBillingAccount = 'UpdateBillingAccount',
    DeleteBillingAccount = 'DeleteBillingAccount',

    ListPaymentProfiles = 'ListPaymentProfiles',
    GetPaymentProfile = 'GetPaymentProfile',
    CreatePaymentProfile = 'CreatePaymentProfile',
    UpdatePaymentProfile = 'UpdatePaymentProfile',
    DeletePaymentProfile = 'DeletePaymentProfile',

    ListBillingTransactions = 'ListBillingTransactions',
    GetBillingTransaction = 'GetBillingTransaction',
    CreateBillingTransaction = 'CreateBillingTransaction',
    UpdateBillingTransaction = 'UpdateBillingTransaction',
    DeleteBillingTransaction = 'DeleteBillingTransaction',

}

export const actions = Object.values(rbacActions);

export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);