import {InformationPropertyCard} from "./InformationPropertyCard.tsx";
import {
    Container,
    Grid,
    Stack
} from "@mantine/core";
import {ProductComponent} from "./ProductComponent.tsx";
import {MetadataPropertyCard} from "./MetadataPropertyCard.tsx";
import {Product} from "../types.ts";


export const Profile = ({record}: { record: Product }) => {


    return (

        <Grid>
            <Grid.Col span={{
                base: 12,
                lg: 4
            }}>
                <Stack gap={'xl'}>
                    <InformationPropertyCard record={record}/>
                    <MetadataPropertyCard record={record}/>
                </Stack>
            </Grid.Col>
            <Grid.Col span={{
                base: 12,
                lg: 8
            }}>
                <Container>
                    <ProductComponent product={record}/>
                </Container>
            </Grid.Col>
        </Grid>

    );


};
