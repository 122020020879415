export const listProductFeatures = `
   query listProductFeatures {
       listProductFeatures {
           items {
              id
              name
              description
              category
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getProduct = `
   query getProductFeature($id:ID!) {
       getProductFeature(id:$id) {
        id
        name
        description
        category
        createdByUsername
        created
        updated
       } 
}`;
