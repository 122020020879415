import {
    Center,
    Paper,
    Table,
    Text,
    Title
} from '@mantine/core';

import {
    AiOutlineCheck,
    AiOutlineClose
} from "react-icons/ai";
import {Product} from "../types.ts";
import {FlexiButton} from "@flexinet/ui-components";
import config from "../../../config";


// product tile component
export const ProductComponent = (
    {
        product
    }: {
        product: Product
    }) => {
    return (
        <Paper shadow="xl" p="xl" withBorder>
            <Center>
                <Title>{product.name}</Title>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>${product.baseFee}</Title>
            </Center>

            <Center>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>minimum / month</Title>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>{product.included}</Title>
            </Center>

            <Center>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>domain names included </Title>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>${product.price}</Title>
            </Center>

            <Center>
                <Title order={2} style={{color: 'var(--mantine-color-dark-3)'}}>domain name / month</Title>
            </Center>


            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Table striped={true}>
                    <Table.Tbody>
                        {product.features && product.features.map(feature =>
                                                                      <Table.Tr key={feature.title}>
                                                                          <Table.Td>{feature.supported ?
                                                                              <AiOutlineCheck color={'green'}/> :
                                                                              <AiOutlineClose color={'red'}/>}
                                                                          </Table.Td>
                                                                          <Table.Td>{feature.title}</Table.Td>
                                                                      </Table.Tr>)}
                    </Table.Tbody>
                </Table>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                <Table striped={true}>
                    <Table.Tbody>
                        {product.products && product.products.items.map(product =>
                                                                            <Table.Tr key={product.id}>
                                                                                <Table.Td>{product.id ?
                                                                                    <AiOutlineCheck color={'green'}/> :
                                                                                    <AiOutlineClose color={'red'}/>}
                                                                                </Table.Td>
                                                                                <Table.Td>{product.name}</Table.Td>
                                                                            </Table.Tr>)}
                    </Table.Tbody>
                </Table>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-lg)'}}>
                {product.freeTrial ? <Title order={4}>Free 30 days trial</Title> :
                    <Title order={4}>Free product</Title>}
            </Center>

            <Center>
                <Text>No credit card required</Text>
            </Center>

            <Center style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                <FlexiButton
                    size={'md'}
                    onClick={() => window.location.assign(config.platform.portal + '/sign-up?application=flexi-domains&product=' + product.id
                    )}><Text tt={'uppercase'}>Start Your Free Trial</Text></FlexiButton>

            </Center>

        </Paper>
    );

}
