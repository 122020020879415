import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {BillingTransaction} from '../types';
import {BillingTransactionStatusBadge} from "../components/BillingTransactionStatusBadge.tsx";

export const columns = [
    {
        title: I18n.get('BillingTransaction Name'),
        width: '1fr',
        render: (item: BillingTransaction) => <NavLink to={`/billing-transactions/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: BillingTransaction) => <BillingTransactionStatusBadge status={item.status}/>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: BillingTransaction) => formatTimestamp(item.created as string),
    },
];
