import {ReloadButton,} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useProducts} from '../hooks/useProducts.ts';

export const ReloadRecordsButton = () => {

    const {fetchRecords} = useProducts();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};
