import {Field} from 'formik';
import {Container} from "@mantine/core";
import {SubscriptionsAutocomplete} from "../../subscriptions/inputs/SubscriptionsAutocomplete.tsx";
import {PlatformFieldset} from "@flexinet/ui-components";
import {BillingAccountsAutocomplete} from "../../billing-accounts/inputs/BillingAccountsAutocomplete.tsx";


export const FormComponent = () => {

    return (
        <Container>
            <PlatformFieldset legend={'Order'}>
                <Field
                    name='customerId'
                    label='Customer'
                    description='Please enter the customer'
                    component={BillingAccountsAutocomplete}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='subscriptionId'
                    label='Subscription'
                    description='Please enter order subscription'
                    component={SubscriptionsAutocomplete}
                    my={'xl'}
                />

            </PlatformFieldset>

        </Container>
    );
};
