import {Field} from 'formik';

import {Grid,} from '@mantine/core';
import {
    FormikSelect,
    FormikTextInput,
    PlatformFieldset
} from "@flexinet/ui-components";
import {Price} from "../types.ts";
import {ProductsAutocomplete} from "../../products/inputs/ProductsAutocomplete.tsx";
import {RatePlansAutocomplete} from "../../rate-plans/inputs/RatePlansAutocomplete.tsx";


export const FormComponent = ({values}: { values: Price }) => {

    console.log('values', values)

    return (
        <Grid>
            <Grid.Col span={{
                base: 12,
                lg: 6
            }} offset={{
                lg: 3
            }}>

                <PlatformFieldset legend={'Price'}>

                    <Field
                        name='name'
                        label='Name'
                        description='Please enter the price name'
                        component={FormikTextInput}
                        withAsterisk
                        my={'xl'}
                    />

                    <Field
                        name='productId'
                        label='Product'
                        description='Please enter the product'
                        component={ProductsAutocomplete}
                        withAsterisk
                        my={'xl'}
                    />


                    <Field
                        name='pricingModel'
                        label='Pricing Model'
                        description='Please enter the billing scheme'
                        data={[{
                            label: 'Flat Rate',
                            value: 'FlatRate'
                        }, {
                            label: 'Package Based',
                            value: 'PackageBased'
                        }, {
                            label: 'Volume Based',
                            value: 'VolumeBased'
                        }, {
                            label: 'Usage Based',
                            value: 'UsageBased'
                        }]}
                        component={FormikSelect}
                        withAsterisk
                        my={'xl'}
                    />

                    <Field
                        name='ratePlanId'
                        label='Rate Plan'
                        description='Please enter the rate plan'
                        component={RatePlansAutocomplete}
                        withAsterisk
                        my={'xl'}
                    />

                </PlatformFieldset>
            </Grid.Col>


        </Grid>
    );
};

