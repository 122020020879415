export const listPrices = `
   query listPrices {
       listPrices {
           items {
              id
              name
              productId
              product {
                id
                name
              }
              pricingModel
              ratePlanId
              ratePlan {
                id
                name
              }
              createdByUsername
              created
           }
       }
   }
`;

export const getPrice = `
   query getPrice($id:ID!) {
       getPrice(id:$id) {
         id
         name
         productId
         product {
            id
            name
         }
         pricingModel
         ratePlanId
         ratePlan {
            id
            name
         }
         createdByUsername
         created
       } 
}`;
