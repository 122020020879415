import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from "./pages/EditPage.tsx";
import {AddPage as AddOrderPage} from '../orders/pages/AddPage';
import {AddPage as AddSubscriptionPage} from '../subscriptions/pages/AddPage';
import {AddPage as AddMeteringRecordPage} from '../metering-records/pages/AddPage';
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";
import {rbacActions} from "../../config/rbac/actions.ts";


export const BillingTransactionsLayout = () => {

    const {allowedActions} = useVerifiedPermissions()


    return (
        <Routes>
            <Route
                path='/'
                element={
                    allowedActions.includes(rbacActions.ListBillingTransactions) ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            />
            <Route
                path='add'
                element={
                    allowedActions.includes(rbacActions.CreateBillingTransaction) ? (
                        <AddPage/>
                    ) : (
                        <Navigate to='/billing-transactions'/>
                    )
                }
            />
            <Route
                path=':id'
                element={
                    allowedActions.includes(rbacActions.GetBillingTransaction) ? (
                        <EditPage/>
                    ) : (
                        <Navigate to='/billing-transactions'/>
                    )
                }

            >
            </Route>


            <Route
                path=':id/orders/add'
                element={
                    allowedActions.includes(rbacActions.CreateOrder) ? (
                        <AddOrderPage/>
                    ) : (
                        <Navigate to='/billing-transactions'/>
                    )
                }

            >
            </Route>

            <Route
                path=':id/subscriptions/add'
                element={
                    allowedActions.includes(rbacActions.CreateSubscription) ? (
                        <AddSubscriptionPage/>
                    ) : (
                        <Navigate to='/billing-transactions'/>
                    )
                }

            >
            </Route>


            <Route
                path=':id/metering-records/add'
                element={
                    allowedActions.includes(rbacActions.CreateMeteringRecord) ? (
                        <AddMeteringRecordPage/>
                    ) : (
                        <Navigate to='/billing-transactions'/>
                    )
                }

            >
            </Route>

        </Routes>
    );
};
