import {BillingTransaction,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardProperty,
} from "@flexinet/ui-components";

import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";


export const ContactInfoPropertyCard = ({record}: { record: BillingTransaction }) => {

    const properties = [
        {
            label: 'Email',
            value: record.contactInfo?.email as string,
            span: 12,
        }, {
            label: 'Phone',
            value: record.contactInfo?.phone as string,
            span: 12,
        }, {
            label: 'Website',
            value: record.contactInfo?.website as string,
            span: 12,
        }] as PlatformPropertyCardProperty[];


    const {filterAllowed} = useAllowedItems()

    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];


    return (<PlatformPropertyCard properties={allowedProperties}/>);


};
