import {Field} from 'formik';

import {
    FormikNumberInput,
    FormikRadioCardGroup,
    FormikRadioGroup,
    FormikSelect,
    FormikSwitch,
    FormikTextarea,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Container} from "@mantine/core";

import {ProductsMultiSelect} from "../../products/inputs/ProductsMultiSelect.tsx";
import {PricesMultiSelect} from "../../prices/inputs/PricesMultiSelect.tsx";
import {ProductStatus} from "../types.ts";


export const FormComponent = () => {

    return (
        <Container>
            <PlatformFieldset legend={'Product'}>

                <Field
                    name='name'
                    label='Name'
                    description='Please enter plan name'
                    component={FormikTextInput}
                    withAsterisk
                />
            </PlatformFieldset>

            <PlatformFieldset legend={'Status'}>

                <Field
                    name='status'
                    label='Status'
                    description='Please enter status'
                    component={FormikRadioGroup}
                    data={
                        [
                            {
                                value: ProductStatus.Draft,
                                label: ProductStatus.Draft,
                            },
                            {
                                value: ProductStatus.Active,
                                label: ProductStatus.Active,
                            },
                            {
                                value: ProductStatus.Deprecated,
                                label: ProductStatus.Deprecated,
                            }
                        ]
                    }
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Details'}>
                <Field
                    name='description'
                    label='Description'
                    description='Please enter the product descripiton'
                    component={FormikTextarea}
                    my={'xl'}
                />

                <Field
                    name='baseFee'
                    label='Base Fee'
                    description='Please enter the base fee'
                    component={FormikTextInput}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Configuration'}>
                <Field
                    name='trialLength'
                    label='Trial Length'
                    description='Please enter the Trial Length if applicable'
                    component={FormikSelect}
                    data={
                        [
                            {
                                value: 0,
                                label: 'No Trial',
                            },
                            {
                                value: 7,
                                label: 'One Week',
                            },
                            {
                                value: 28,
                                label: 'One Month',
                            }
                        ]
                    }
                    my={'xl'}
                />

                <Field
                    name='setupFee'
                    label='Setup Fee'
                    description='Do you want to charge setup fee?'
                    component={FormikTextInput}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Billing Configuration'}>
                <Field
                    name='billingPeriod'
                    label='Billing Period'
                    description='How often should subscribers be billed'
                    component={FormikSelect}
                    data={
                        [
                            {
                                value: 1,
                                label: '1 Month',
                            },
                            {
                                value: 3,
                                label: '3 Months',
                            },
                            {
                                value: 12,
                                label: 'One Year',
                            }
                        ]
                    }
                    my={'xl'}
                />

                <Field
                    name='subscriptionTermLength'
                    label='Subscription Term Length'
                    description='How many billing periods is the subscription for'
                    component={FormikNumberInput}
                    my={'xl'}
                />

                <Field
                    name='totalBillingSycles'
                    label='Total Billing Cycles'
                    description='Total Billing Cycles'
                    component={FormikNumberInput}
                    my={'xl'}
                />

                <Field
                    name='autoRenew'
                    label='Auto Renew'
                    description='Auto Renew'
                    component={FormikSwitch}
                    my={'xl'}
                />

            </PlatformFieldset>


            <PlatformFieldset legend={'Pricing Model'}>
                <Field
                    name='pricingModel'
                    label='Pricing Model'
                    description='Please enter the pricing model'
                    component={FormikRadioCardGroup}
                    data={
                        [
                            {
                                value: 'fixed',
                                label: 'Fixed',
                                description: 'Fixed Pricing Model',
                            },
                            {
                                value: 'ramp',
                                label: 'Ramp',
                                description: 'Ramp Pricing Model',
                            }
                        ]
                    }
                    my={'xl'}
                />


            </PlatformFieldset>


            <PlatformFieldset legend={'Products'}>
                <Field
                    name='productIds'
                    label='Products'
                    description='Please enter the products'
                    component={ProductsMultiSelect}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Prices'}>
                <Field
                    name='priceIds'
                    label='Prices'
                    description='Please enter the prices'
                    component={PricesMultiSelect}
                    my={'xl'}
                />

            </PlatformFieldset>

        </Container>
    );
};