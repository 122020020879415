import {
    getBillingTransaction,
    listBillingTransactions
} from './queries';
import {
    createBillingTransaction,
    deleteBillingTransaction,
    updateBillingTransaction,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {BillingTransaction} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";


export type ListBillingTransactions = {
    listBillingTransactions: { items: BillingTransaction[] }
}

export type GetBillingTransaction = {
    getBillingTransaction: BillingTransaction
}

export type CreateBillingTransaction = {
    createBillingTransaction: BillingTransaction
}

export type UpdateBillingTransaction = {
    updateBillingTransaction: BillingTransaction
}

export type DeleteBillingTransaction = {
    deleteBillingTransaction: string
}


const client = generateClient();


export const fetchRecords = async (query: { billingProviderId?: string, billingProviderRecordId?: string }) => {

    let filter = {}

    if (query.billingProviderId) {
        filter = {
            billingProviderId: {
                eq: query.billingProviderId
            }
        }
    }

    if (query.billingProviderRecordId) {
        filter = Object.assign(filter, {
            billingProviderRecordId: {
                eq: query.billingProviderRecordId
            }
        })
    }

    try {
        const response = await client.graphql<GraphQLQuery<ListBillingTransactions>>({
                                                                                         query: listBillingTransactions,
                                                                                         variables: {
                                                                                             filter: filter
                                                                                         },
                                                                                     });
        return response.data?.listBillingTransactions.items;
    } catch (e: any) {
        console.trace(e);
        if (typeof e !== 'undefined' && typeof e.errors !== 'undefined') {
            const unauthorizedExceptionFound = e.errors.find((item: {
                errorType: string,
                message: string
            }) => item.errorType === 'UnauthorizedException')
            if (unauthorizedExceptionFound) {
                window.location.href = '/'
            }
        }
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetBillingTransaction>>({
                                                                                       query: getBillingTransaction,
                                                                                       variables: {id: id},
                                                                                   });
        return response.data?.getBillingTransaction;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateBillingTransaction>>({
                                                                                          query: createBillingTransaction,
                                                                                          variables: {input: payload},
                                                                                      });
        console.debug('in the service response', response);
        notifySuccess('BillingTransaction created')
        return response.data?.createBillingTransaction;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: BillingTransaction) => {

    console.debug('IN UPDATE', id, record);

    const {
        createdByUsername,
        created,
        updated,
        ...rest
    } = record

    try {
        const response = await client.graphql<GraphQLQuery<UpdateBillingTransaction>>({
                                                                                          query: (updateBillingTransaction),
                                                                                          variables: {input: rest},
                                                                                      });
        notifySuccess('BillingTransaction updated')
        return response.data?.updateBillingTransaction;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteBillingTransaction>>({
                                                                                          query: (deleteBillingTransaction),
                                                                                          variables: {input: {id: id}},

                                                                                      });
        notifySuccess('BillingTransaction deleted')
        return response.data?.deleteBillingTransaction;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
