import {
    deleteBillingTransaction,
    fetchBillingTransaction,
    saveBillingTransaction,
    updateBillingTransaction,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {BillingTransaction} from '../types';

export const useBillingTransaction = (billingTransactionId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, billingTransactionId ? billingTransactionId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof billingTransactionId === 'undefined') {
            throw new Error('billingTransactionId is required parameter');
        }
        dispatch(fetchBillingTransaction(billingTransactionId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: BillingTransaction) => {
        dispatch(saveBillingTransaction(values));
    };

    const updateRecord = (values: BillingTransaction) => {
        if (typeof billingTransactionId === 'undefined') {
            throw new Error('billingTransactionId is required parameter');
        }

        dispatch(updateBillingTransaction({
                                              id: billingTransactionId,
                                              record: values,
                                          }));
    };

    const deleteRecord = () => {
        if (typeof billingTransactionId === 'undefined') {
            throw new Error('billingTransactionId is required parameter');
        }
        dispatch(deleteBillingTransaction(billingTransactionId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
