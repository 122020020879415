import {Field} from 'formik';

import {
    FormikDateTimePicker,
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Box} from "@mantine/core";
import {MetersAutocomplete} from "../../meters/inputs/MetersAutocomplete.tsx";
import {FormikList} from "../../../components/FormikList";
import {MeterDimensionsDropdown} from "../../meters/inputs/MeterDimensionsDropdown.tsx";
import {MeteringRecord} from "../types.ts";
import {BillingAccountsAutocomplete} from "../../billing-accounts/inputs/BillingAccountsAutocomplete.tsx";


export const FormComponent = ({values}: { values: MeteringRecord }) => {

    return (
        <Box>
            <PlatformFieldset legend={'Metering Record'}>
                <Field
                    name='customerId'
                    label='Customer'
                    description='Please select the customer'
                    component={BillingAccountsAutocomplete}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='meterId'
                    label='Meter'
                    description='Please select the meter'
                    component={MetersAutocomplete}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='meterValue'
                    label='Meter Value'
                    description='Please enter the meter value'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='timestamp'
                    label='Timestamp'
                    description='Please enter the timestamp'
                    component={FormikDateTimePicker}
                    withAsterisk
                    my={'xl'}
                />

            </PlatformFieldset>

            {values.meterId ?
                <PlatformFieldset legend={'Meter Dimensions'}>
                    <Field
                        name='dimensions'
                        label='Dimensions'
                        description='Please enter the dimensions'
                        getRowElements={() => [{
                            name: 'apiKey',
                            placeholder: 'API Key',
                            component: MeterDimensionsDropdown,
                            meterId: values.meterId
                        }, {
                            name: 'value',
                            placeholder: 'Value',
                            component: FormikTextInput
                        }]}
                        component={FormikList}
                    /></PlatformFieldset> : null}

        </Box>
    );
};
