import {ITableColumn} from '../../types';
import {ReactNode} from 'react';

export type RatePlanDimension = { name: string, value: string }

export type ChargeTier = {
    lowerBand: string
    upperBand: string
    rate: string
}

export enum BillablePeriod {
    MONTH = 'month',
    QUARTER = 'quarter',
    YEAR = 'year',
}

export enum BillableUnit {
    DOMAIN_NAME = 'domain_name',
    REGISTRAR = 'registrar',
    EMAIL_ALIAS = 'email_alias',
    USER = 'user',
    TRANSFER = 'transfer',
    STORAGE = 'storage',
    SERVICE = 'service',
    CUSTOM = 'custom',
}

export type RatePlan = {
    id: string,
    name: string,
    billableUnit: BillableUnit,
    billablePeriod: BillablePeriod,
    chargeTiers: ChargeTier[],
    createdByUsername?: string,
    createdBy?: { givenName: string, familyName: string },
    created?: string,
    updated?: string,
}


export interface IRatePlanTableColumn extends ITableColumn {
    render?: (item: RatePlan) => ReactNode;
}
