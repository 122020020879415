export const createPrice = `
    mutation createPrice($input: CreatePriceInput!) {
        createPrice(input:$input) {
            id
            name
            productId
            product {
                id
                name
            }
            pricingModel
            ratePlanId
            ratePlan {
                id
                name
            }
    }
}`;

export const updatePrice = `
    mutation updatePrice($input: UpdatePriceInput!) {
        updatePrice(input:$input){
            id
            name
            productId
            product {
                id
                name
            }
            pricingModel
            ratePlanId
            ratePlan {
                id
                name
            }
        }
}`;

export const deletePrice = `
    mutation deletePrice($input: DeletePriceInput!) {
        deletePrice(input:$input) 
}`;
