import {Order,} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator,
    PlatformPropertyCardProperty
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";
import {NavLink} from 'react-router-dom';
import {CreatedByProfileFormatter} from '../../../components/CreatedByProfileFormatter.tsx';


export const Profile = ({record}: { record: Order }) => {

    const properties = [
        {
            label: 'Customer',
            value: <NavLink to={`/customers/${record.customerId}`}>{record.customerId}</NavLink>,
            span: 6,
        }, {
            label: 'Subscription',
            value: <NavLink to={`/customers/${record.customerId}/#subscriptions`}>{record.subscriptionId}</NavLink>,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 6,
        }, {
            label: 'Created By',
            value: <CreatedByProfileFormatter record={record as any}/>,
            rbac: 'UpdateOrder',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (
        <PlatformPropertyCard properties={allowedProperties}/>
    );


};

