import {Invoice,} from '../types';
import {PlatformPropertyCard} from "@flexinet/ui-components";
import {CreatedByProfileFormatter} from "../../../components/CreatedByProfileFormatter.tsx";

export const Profile = ({record}: { record: Invoice }) => {

    const properties = [
        {
            label: 'Name',
            value: record.id as string,
            span: 6,
        }, {
            label: 'Description',
            value: record.id as string,
            span: 6,
        }, {
            label: 'Created By',
            value: <CreatedByProfileFormatter record={record as any}/>,
            rbac: 'UpdateInvoice',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }];


    return (
        <PlatformPropertyCard properties={properties}/>
    );


};