export const createBillingTransaction = `
    mutation createBillingTransaction($input: CreateBillingTransactionInput!) {
        createBillingTransaction(input:$input) {
            id
            name
            description      
            status 
            created
            updated  
    }
}`;

export const updateBillingTransaction = `
    mutation updateBillingTransaction($input: UpdateBillingTransactionInput!) {
        updateBillingTransaction(input:$input){
            id
            name
            description
            status  
            created
            updated       
        }
}`;

export const deleteBillingTransaction = `
    mutation deleteBillingTransaction($input: DeleteBillingTransactionInput!) {
        deleteBillingTransaction(input:$input) 
}`;
