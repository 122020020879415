import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator,
    PlatformPropertyCardProperty
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";
import {rbacActions} from "../../../config/rbac/actions.ts";
import {ProductFeature} from "../types.ts";
import {CreatedByProfileFormatter} from "../../../components/CreatedByProfileFormatter.tsx";


export const Profile = ({record}: { record: ProductFeature }) => {


    const properties = [
        {
            label: 'Product Name',
            value: record.name,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }, {
            label: 'Product Description',
            value: record.description as string,
            span: 12,
        }, {
            label: 'Category',
            value: record.category as string,
            span: 12,
        }, {
            label: 'Created By',
            value: <CreatedByProfileFormatter record={record as any}/>,
            rbac: rbacActions.UpdateProductFeature,
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }] as PlatformPropertyCardProperty[];


    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (
        <PlatformPropertyCard properties={allowedProperties}/>
    );


};
