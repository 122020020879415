interface UserProfile {
    createdByUsername: string;
    createdBy?: {
        givenName: string;
        familyName: string;
    };
}

export const CreatedByProfileFormatter = ({record}: { record: UserProfile }): string => {

    const {
        createdByUsername,
        createdBy
    } = record;


    if (!createdByUsername) {
        throw new Error('createdByUsername is required');
    }

    if (createdBy?.givenName && createdBy?.familyName) {
        // Trim each name individually and join with a single space
        const formattedGivenName = createdBy.givenName.trim();
        const formattedFamilyName = createdBy.familyName.trim();

        if (formattedGivenName && formattedFamilyName) {
            return `${formattedGivenName} ${formattedFamilyName}`;
        }
    }

    return createdByUsername;
};
