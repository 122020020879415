import {
    getProduct,
    listProductFeatures
} from './queries';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreateProductFeature,
    DeleteProductFeature,
    GetProductFeature,
    ListProductFeatures,
    ProductFeature,
    UpdateProductFeature
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";
import config from "../config";
import {
    createProductFeature,
    deleteProductFeature,
    updateProductFeature
} from "./mutations.ts";


const client = generateClient();


export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListProductFeatures>>({
                                                                                     query: listProductFeatures,
                                                                                     variables: {filter: filter},
                                                                                 });
        return response.data?.listProductFeatures.items;
    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetProductFeature>>({
                                                                                   query: getProduct,
                                                                                   variables: {id: id},
                                                                               });
        return response.data?.getProductFeature;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateProductFeature>>({
                                                                                      query: createProductFeature,
                                                                                      variables: {input: payload},
                                                                                  });
        console.debug('in the service response', response);
        notifySuccess(`${config.titles.singular} created`);
        return response.data?.createProductFeature;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: ProductFeature) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateProductFeature>>({
                                                                                      query: (updateProductFeature),
                                                                                      variables: {input: record},
                                                                                  });
        notifySuccess(`${config.titles.singular} updated`)
        return response.data?.updateProductFeature;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteProductFeature>>({
                                                                                      query: (deleteProductFeature),
                                                                                      variables: {input: {id: id}},

                                                                                  });
        notifySuccess(`${config.titles.singular} deleted`)
        return response.data?.deleteProductFeature;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
