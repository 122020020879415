import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Product} from '../types';
import {StatusBadge} from "../profile/StatusBadge.tsx";

export const columns = [
    {
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: Product) => <NavLink to={`/products/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('Free Trial'),
        width: '1fr',
        render: (item: Product) => item.freeTrial ? 'yes' : 'no',
    },
    {
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: Product) => <StatusBadge status={item.status}/>,
    },
    {
        title: I18n.get('Billing Provider'),
        width: '1fr',
        render: (item: Product) => item.billingProviderId,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Product) => formatTimestamp(item.created as string),
    },
    {
        title: I18n.get('Last Updated'),
        width: '1fr',
        render: (item: Product) => item.updated ? formatTimestamp(item.updated as string) : 'n/a',
    },
];
