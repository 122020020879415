import {
    getProduct,
    listProducts
} from './queries';
import {
    createProduct,
    deleteProduct,
    updateProduct,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Product} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";
import config from "../config";
import {products} from "./products.tsx";


export type ListProducts = {
    listProducts: { items: Product[] }
}

export type GetProduct = {
    getProduct: Product
}

export type CreateProduct = {
    createProduct: Product
}

export type UpdateProduct = {
    updateProduct: Product
}

export type DeleteProduct = {
    deleteProduct: string
}


const client = generateClient();


export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListProducts>>({
                                                                              query: listProducts,
                                                                              variables: {filter: filter},
                                                                          });
        return [...products, ...response.data?.listProducts.items];

    } catch (e) {
        console.trace(e);
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetProduct>>({
                                                                            query: getProduct,
                                                                            variables: {id: id},
                                                                        });
        return response.data?.getProduct;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateProduct>>({
                                                                               query: createProduct,
                                                                               variables: {input: payload},
                                                                           });
        console.debug('in the service response', response);
        notifySuccess(`${config.titles.singular} created`);
        return response.data?.createProduct;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: Product) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateProduct>>({
                                                                               query: (updateProduct),
                                                                               variables: {input: record},
                                                                           });
        notifySuccess(`${config.titles.singular} updated`)
        return response.data?.updateProduct;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteProduct>>({
                                                                               query: (deleteProduct),
                                                                               variables: {input: {id: id}},

                                                                           });
        notifySuccess(`${config.titles.singular} deleted`)
        return response.data?.deleteProduct;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
