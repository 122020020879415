export const createProductFeature = `
    mutation createProductFeature($input: CreateProductFeatureInput!) {
        createProductFeature(input:$input) {
            id
            name
            description
            category
            created
            updated
    }
}`;

export const updateProductFeature = `
    mutation updateProductFeature($input: UpdateProductFeatureInput!) {
        updateProductFeature(input:$input){
            id
            name
            description
            category
            created
            updated
        }
}`;

export const deleteProductFeature = `
    mutation deleteProductFeature($input: DeleteProductFeatureInput!) {
        deleteProductFeature(input:$input) 
}`;
