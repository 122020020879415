import {Field} from 'formik';

import {
    FormikTextInput,
    PlatformFieldset
} from '@flexinet/ui-components';
import {Container} from "@mantine/core";
import {ProductsAutocomplete} from "../../products/inputs/ProductsAutocomplete.tsx";
import {BillingAccountsAutocomplete} from "../../billing-accounts/inputs/BillingAccountsAutocomplete.tsx";


export const FormComponent = () => {

    return (
        <Container>
            <PlatformFieldset legend={'Subscription'}>
                <Field
                    name='name'
                    label='Name'
                    description='Please enter the subscription name'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='customerId'
                    label='Customer'
                    description='Please enter customer'
                    component={BillingAccountsAutocomplete}
                    my={'xl'}
                />

                <Field
                    name='productId'
                    label='Product'
                    description='Please enter product'
                    component={ProductsAutocomplete}
                    my={'xl'}
                />

            </PlatformFieldset>

        </Container>
    );
};
