import {
    getBillingAccount,
    listBillingAccounts
} from './queries';
import {
    createBillingAccount,
    deleteBillingAccount,
    updateBillingAccount,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    BillingAccount,
    CreateBillingAccount,
    DeleteBillingAccount,
    GetBillingAccount,
    ListBillingAccounts,
    UpdateBillingAccount
} from "../types.ts";
import {
    notifyError,
    notifySuccess
} from "../../../components/PlatformNotification";
import {generateClient} from "aws-amplify/api";
import config from "../config";


const client = generateClient();


export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListBillingAccounts>>(
            {
                query: listBillingAccounts,
                variables: {filter: filter},
            });
        return response.data?.listBillingAccounts.items;
    } catch (e: any) {
        console.trace(e);
        if (typeof e !== 'undefined' && typeof e.errors !== 'undefined') {
            const unauthorizedExceptionFound = e.errors.find((item: {
                errorType: string,
                message: string
            }) => item.errorType === 'UnauthorizedException')
            if (unauthorizedExceptionFound) {
                window.location.href = '/'
            }
        }
        notifyError(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetBillingAccount>>(
            {
                query: getBillingAccount,
                variables: {id: id},
            });
        return response.data?.getBillingAccount;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateBillingAccount>>(
            {
                query: createBillingAccount,
                variables: {input: payload},
            });
        console.debug('in the service response', response);
        notifySuccess(`${config.titles.singular} created`)
        return response.data?.createBillingAccount;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const update = async (id: string, record: BillingAccount) => {

    console.debug('IN UPDATE', id, record);

    const {
        createdByUsername,
        created,
        updated,
        ...rest
    } = record

    try {
        const response = await client.graphql<GraphQLQuery<UpdateBillingAccount>>(
            {
                query: (updateBillingAccount),
                variables: {input: rest},
            });
        notifySuccess(`${config.titles.singular} updated`)
        return response.data?.updateBillingAccount;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteBillingAccount>>(
            {
                query: (deleteBillingAccount),
                variables: {input: {id: id}},

            });
        notifySuccess(`${config.titles.singular} deleted`)
        return response.data?.deleteBillingAccount;
    } catch (e) {
        console.trace(e);
        notifyError(e)
    }
};
