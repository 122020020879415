import {Field} from 'formik';

import {SimpleGrid} from '@mantine/core';
import {BillingAccountsAutocomplete} from "../../billing-accounts/inputs/BillingAccountsAutocomplete.tsx";


export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='billingAccountId'
                label='Billing Account'
                description='Please enter the billing account'
                component={BillingAccountsAutocomplete}
                withAsterisk
            />

        </SimpleGrid>
    );
};
