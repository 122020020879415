import {
    setPage,
    setPageSize,
} from '../reducers';

import {visibilitySelector,} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';

export const useProductFeaturesPaging = () => {

    const dispatch = useAppDispatch();


    const {
        limit,
        page,
        totalPages,
    } =
        useAppSelector((state) => visibilitySelector(state));


    const handleItemsPerPageChange = (pageSize: string | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };


    return {
        limit,
        page,
        totalPages,
        handleItemsPerPageChange,
        handlePageChange,
    };
};
